export const total = `<ul><li>This is everything everywhere all at once</li></ul>`

export const june25 = `<ul><li>NATO and FTW declare on Doom alliances</li></ul>`

export const june26 = `<ul><li>CLAWS declares on FTW and NATO</li></ul>`

export const june28 = `<ul><li>NPO and DT declare on FTW and NATO</li></ul>`

export const july1 = `<ul><li>Legion declares on NPO</li></ul>`

export const july3 = `<ul><li>Sparta declares on NPO</li></ul>`

export const july5 = `<ul><li>Ender Order declares on CLAWS and NPO and maybe some other people? Idk it's not really clear what they were looking to do.</li><li>NG declares on NPO</li><li>CCC declares on NPO</li><li>Fail reforms and declares on NPO</li></ul>`

export const july6 = `<ul><li>GloF declares war on FTW and NATO</li><li>IRON declares war on NG and Sparta</li><li>NpO declares war on NG and CCC</li><li>CLAWS declares war on CCC</li><li>TKTB declares war on CCC</li></ul>`

export const july7 = `<ul><li>GATO and Argent declare war on CCC and Sparta</li></ul>`

export const july9 = `<ul><li>New NpO leadership white peaces its war with NG</li></ul>`

export const july15 = `<ul><li>TCTB forms and declares war on TKTB</li></ul>`

export const july17 = `<ul><li>MHA declares war on DS and DW</li><li>EO merges into TCTB</li></ul>`

export const july19 = `<ul><li>OBR and OG join in, because why not, and declare on Doom</li><li>NG declares on TKTB</li></ul>`

export const july21 = `<ul><li>TLR declares war on IRON</li></ul>`

export const july22 = `<ul><li>Mongols forms and declares on NPO and IRON</li><li>VE declares on NG</li></ul>`

export const july24 = `<ul><li>Ouroboros declares on CLAWS (Hey Johnny 😘)</li><li>NoR declares on VE</li></ul>`

export const july25 = `<ul><li>KoRT declares on CCC</li></ul>`

export const july29 = `<ul><li>Kashmir rebrands as PGS and declares on CCC</li><li>"Kashmir" forms 🙄 and declares on NPO, IRON, VE, and TKTB</li></ul>`

export const july30 = `<ul><li>WWF declares on OBR and OG?</li></ul>`

export const july31 = `<ul><li>"Kashmir" disbands and merges into NG</li></ul>`

export const aug12 = `<ul><li>FAN declares on VE</li><li>Fark declares on VE</li></ul>`

export const aug14 = `<ul><li>RIA declares on VE</li></ul>`

export const aug16 = `<ul><li>NpO declares on FAN</li></ul>`