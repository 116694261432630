import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import Graph from "react-vis-network-graph";
import * as alliances from '../../helpers/alliances';
import * as declarations from '../../helpers/declarations';
import * as updates from '../../helpers/updates';
import "./styles.scss";

const IndexPage = () => {

  const [date, newDate] = useState('total');

  useEffect(() => {
    const canvas = document.getElementById('mynetwork');
    if (document.body.contains(canvas)) {

      if (window.outerWidth < 1280) {
        const options = document.getElementById('top');
        const optionHeight = options.offsetHeight;
        canvas.style.height = `calc(100% - ${optionHeight}px)`;
      } else {
        canvas.style.height = '100%';
      }
      if (window.outerWidth >= 1024) {
        // window.network.setOptions( { edges: { smooth: { type: "dynamic" } } } )
      }
    }

      // var network = new window.vis.Network();
  
    return () => {};
  });

  useEffect(() => {
    const dateSelector = document.getElementById("nodeFilterSelectDate");

    let dateOptions = '';
    const filteredDates = Object.keys(alliances).filter(ele => ele !== 'total');

    const sortedDates = filteredDates.sort((a, b) => {
      // const splitDate = date.split(/(\d+)/);
      const formattedDateA = `${a.split(/(\d+)/)[0].charAt(0).toUpperCase() + a.split(/(\d+)/)[0].slice(1)} ${a.split(/(\d+)/)[1]}`
      const formattedDateB = `${b.split(/(\d+)/)[0].charAt(0).toUpperCase() + b.split(/(\d+)/)[0].slice(1)} ${b.split(/(\d+)/)[1]}`
      console.log(new Date(formattedDateB) - new Date(formattedDateA))
      return new Date(formattedDateA) - new Date(formattedDateB);
    });
    console.log(sortedDates);

    sortedDates.forEach(date => {
      const splitDate = date.split(/(\d+)/);
      const formattedDate = `${splitDate[0].charAt(0).toUpperCase() + splitDate[0].slice(1)} ${splitDate[1]}`
      dateOptions += `<option value=${date}>${formattedDate}</option>`
    })
    dateSelector.innerHTML += dateOptions;
  }, [])

  const graph = {
    nodes: alliances[date],
    edges: declarations[date],
  }

  const options = {
    nodes: {
      size: 10,
    },
    edges: {
      color: 'black',
      length: 150,
    },
    physics: {
      enabled: true,
      solver: "forceAtlas2Based",
    },
    interaction: {
      zoomView: false
    },
    autoResize: true,
  }


  return (
    <>
    <Helmet>
          <script src="https://unpkg.com/vis-network@9.1.2/standalone/umd/vis-network.min.js" />
        </Helmet>
    <main>
      <div id="top">
        <h1 className="title">The 18th War of Argent Relevancy</h1>
        <h2 className="date">Updated Aug 14, 2022</h2>
        <div className="options">
          <button className="test">
            Drag and Drop
          </button>
          <button className="test">
            Zoomable
          </button>
          {/* <button className="test">
            Straight Lines
          </button>
          <button className="test" id="mutualsOnly">
            Mutuals Only
          </button> */}
          <select className="test" id="nodeFilterSelectDate">
            <option value="total">All Dates</option>
          </select>
          {/* <select className="test" id="nodeFilterSelect">
            <option value="">All Alliances</option>
          </select> */}
        </div>
        <div dangerouslySetInnerHTML={{__html: updates[date]}} />
        <p style={{position: "absolute", bottom: 0, margin: 0}}>Made with hate by Jazzy95 (send me tech, ty)</p>
      </div>
      <div id="mynetwork">
        <Graph
          graph={graph}
          options={options}
          events
          getNetwork={network => {
            let allianceOptions = '';
            // const nodeFilterSelector = document.getElementById("nodeFilterSelect");
            // const allAlliances = network.body.nodes;
            // network.body.nodeIndices.forEach(alliance => {
            //   allianceOptions += `<option value=${alliance}>${alliance}</option>`
            // })
            // nodeFilterSelector.innerHTML += allianceOptions;

            document.addEventListener("click", e =>{
              if ( 'BUTTON' === e.target.nodeName ) {
                switch (e.target.innerText) {
                  case 'Drag and Drop':
                    e.target.classList.toggle('active');
                    network.setOptions( { physics: ! network.physics.physicsEnabled } );
                    break;
                  case 'Zoomable':
                    e.target.classList.toggle('active');
                    network.setOptions( { interaction: { zoomView : ! network.interactionHandler.options.zoomView } } );
                    break;
                  case 'Straight Lines':
                    e.target.classList.toggle('active');
                    network.setOptions( { edges: { smooth: ! network.edgesHandler.options.smooth.enabled } } );
                    break;
                  case 'Delete Selection':
                    network.deleteSelected();
                    break;
                  case '"Legend"':
                    alert('Sorry I haven\'t built a legend yet. Solid lines are mutual defense and dashes are optional.\n\nJazzy95');
                    break;
                  default:
                    break;
                }
              }
            });

            // const nodes = new window.vis.DataSet(alliances);

            // const edges = new window.vis.DataSet(declarations);
            // const nodesView = new window.vis.DataView(nodes, () => {});
            const dateSelector = document.getElementById("nodeFilterSelectDate");
            dateSelector.addEventListener("change", (e) => {
              // // set new value to filter variable
              // nodeFilter.value = dateSelector;
              // nodesView.refresh();
              console.log(e.target.value)
              console.log(dateSelector.value)

              newDate(e.target.value);
            })
        }}
      />
    </div>
    </main>
    </>
  )
}

export default IndexPage

export const Head = () => <title>Home Page</title>
