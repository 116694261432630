const hegemony = { color: 'red' }
const pixel = { color: 'blue' }

export const total = [
  { from: 'FTW', to: 'DBDC', arrows: 'to' },
  { from: 'FTW', to: 'DS', arrows: 'to' },
  { from: 'NATO', to: 'DBDC', arrows: 'to' },
  { from: 'NATO', to: 'DS', arrows: 'to' },
  { from: 'CLAWS', to: 'FTW', arrows: 'to' },
  { from: 'CLAWS', to: 'NATO', arrows: 'to' },
  { from: 'NPO', to: 'FTW', arrows: 'to' },
  { from: 'NPO', to: 'NATO', arrows: 'to' },
  { from: 'DT', to: 'FTW', arrows: 'to' },
  { from: 'DT', to: 'NATO', arrows: 'to' },
  { from: 'Legion', to: 'NPO', arrows: 'to' },
  { from: 'Sparta', to: 'NPO', arrows: 'to' },
  { from: 'EO', to: 'CLAWS', arrows: 'to' },
  { from: 'EO', to: 'NPO', arrows: 'to' },
  { from: 'NG', to: 'NPO', arrows: 'to' },
  { from: 'FAIL', to: 'NPO', arrows: 'to' },
  { from: 'GLoF', to: 'FTW', arrows: 'to' },
  { from: 'GLoF', to: 'NATO', arrows: 'to' },
  { from: 'IRON', to: 'NG', arrows: 'to' },
  { from: 'IRON', to: 'Sparta', arrows: 'to' },
  { from: 'NpO', to: 'NG', arrows: 'to' },
  { from: 'NpO', to: 'CCC', arrows: 'to' },
  { from: 'CLAWS', to: 'CCC', arrows: 'to' },
  { from: 'TKTB', to: 'CCC', arrows: 'to' },
  { from: 'Argent', to: 'Sparta', arrows: 'to' },
  { from: 'Argent', to: 'CCC', arrows: 'to' },
  { from: 'GATO', to: 'Sparta', arrows: 'to' },
  { from: 'GATO', to: 'CCC', arrows: 'to' },
  { from: 'TCTB', to: 'TKTB', arrows: 'to' },
  { from: 'NpO', to: 'Sparta', arrows: 'to' },
  { from: 'MHA', to: 'DW', arrows: 'to' },
  { from: 'MHA', to: 'DS', arrows: 'to' },
  { from: 'OBR', to: 'DW', arrows: 'to' },
  { from: 'OBR', to: 'DS', arrows: 'to' },
  { from: 'OBR', to: 'DBDC', arrows: 'to' },
  { from: 'OG', to: 'DS', arrows: 'to' },
  { from: 'OG', to: 'DW', arrows: 'to' },
  { from: 'OG', to: 'DBDC', arrows: 'to' },
  { from: 'TLR', to: 'IRON', arrows: 'to' },
  { from: 'Mongols', to: 'NPO', arrows: 'to' },
  { from: 'Mongols', to: 'IRON', arrows: 'to' },
  { from: 'VE', to: 'NG', arrows: 'to' },
  { from: 'Ouroboros', to: 'CLAWS', arrows: 'to' },
  { from: 'NoR', to: 'VE', arrows: 'to' },
  { from: 'KoRT', to: 'CCC', arrows: 'to' },
  { from: 'PGS', to: 'CCC', arrows: 'to' },
  { from: '"Kashmir"', to: 'NPO', arrows: 'to' },
  { from: '"Kashmir"', to: 'IRON', arrows: 'to' },
  { from: '"Kashmir"', to: 'VE', arrows: 'to' },
  { from: '"Kashmir"', to: 'TKTB', arrows: 'to' },
  { from: 'WWF', to: 'OBR', arrows: 'to' },
  { from: 'WWF', to: 'OG', arrows: 'to' },
  { from: `Fark`, to: 'VE', arrows: 'to'},
  { from: `FAN`, to: 'VE', arrows: 'to' },
  { from: 'NG', to: 'TKTB', arrows: 'to'  },
  { from: 'TLR', to: 'IRON', arrows: 'to' },
  { from: 'Mongols', to: 'NPO', arrows: 'to' },
  { from: 'Mongols', to: 'IRON', arrows: 'to' },
  { from: 'VE', to: 'NG', arrows: 'to' },
  { from: 'Ouroboros', to: 'CLAWS', arrows: 'to' },
  { from: 'NoR', to: 'VE', arrows: 'to' },
  { from: 'KoRT', to: 'CCC', arrows: 'to' },
  { from: 'PGS', to: 'CCC', arrows: 'to' },
  { from: `"Kashmir"`, to: 'NPO', arrows: 'to' },
  { from: `"Kashmir"`, to: 'IRON', arrows: 'to' },
  { from: `"Kashmir"`, to: 'VE', arrows: 'to' },
  { from: `"Kashmir"`, to: 'TKTB', arrows: 'to' },
  { from: 'WWF', to: 'OBR', arrows: 'to' },
  { from: 'WWF', to: 'OG', arrows: 'to' },
  { from: `Fark`, to: 'VE', arrows: 'to' },
  { from: `FAN`, to: 'VE', arrows: 'to' },
  { from: `RIA`, to: 'VE', arrows: 'to' },
  { from: 'NpO', to: 'FAN', arrows: 'to' },
]

export const june25 = [
  { from: 'FTW', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'FTW', to: 'DS', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DS', arrows: 'to', ...pixel },
]

export const june26 = [
  { from: 'FTW', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'FTW', to: 'DS', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DS', arrows: 'to', ...pixel },
  { from: 'CLAWS', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'NATO', arrows: 'to', ...hegemony },
]

export const june28 = [
  { from: 'FTW', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'FTW', to: 'DS', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DS', arrows: 'to', ...pixel },
  { from: 'CLAWS', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'NATO', arrows: 'to', ...hegemony },
]

export const july1 = [
  { from: 'FTW', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'FTW', to: 'DS', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DS', arrows: 'to', ...pixel },
  { from: 'CLAWS', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'Legion', to: 'NPO', arrows: 'to', ...pixel },
]

export const july3 = [
  { from: 'FTW', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'FTW', to: 'DS', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DS', arrows: 'to', ...pixel },
  { from: 'CLAWS', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'Legion', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Sparta', to: 'NPO', arrows: 'to', ...pixel },
]

export const july5 = [
  { from: 'FTW', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'FTW', to: 'DS', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DS', arrows: 'to', ...pixel },
  { from: 'CLAWS', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'Legion', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Sparta', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'EO', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'NG', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'CCC', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'FAIL', to: 'NPO', arrows: 'to', ...pixel },
]

export const july6 = [
  { from: 'FTW', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'FTW', to: 'DS', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DS', arrows: 'to', ...pixel },
  { from: 'CLAWS', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'Legion', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Sparta', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'EO', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'NG', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'CCC', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'FAIL', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'GLoF', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'GLoF', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'NpO', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'NpO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'TKTB', to: 'CCC', arrows: 'to', ...hegemony },
]

export const july7 = [
  { from: 'FTW', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'FTW', to: 'DS', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DS', arrows: 'to', ...pixel },
  { from: 'CLAWS', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'Legion', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Sparta', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'EO', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'NG', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'CCC', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'FAIL', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'GLoF', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'GLoF', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'NpO', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'NpO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'TKTB', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'Sparta', arrows: 'to', ...hegemony },
]

export const july9 = [
  { from: 'FTW', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'FTW', to: 'DS', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DS', arrows: 'to', ...pixel },
  { from: 'CLAWS', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'Legion', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Sparta', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'EO', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'NG', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'CCC', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'FAIL', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'GLoF', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'GLoF', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'NpO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'TKTB', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'Sparta', arrows: 'to', ...hegemony },
]

export const july15 = [
  { from: 'FTW', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'FTW', to: 'DS', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DS', arrows: 'to', ...pixel },
  { from: 'CLAWS', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'Legion', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Sparta', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'EO', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'NG', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'CCC', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'FAIL', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'GLoF', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'GLoF', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'NpO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'TKTB', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'TCTB', to: 'TKTB', arrows: 'to', ...pixel },
]

export const july17 = [
  { from: 'FTW', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'FTW', to: 'DS', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DS', arrows: 'to', ...pixel },
  { from: 'CLAWS', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'Legion', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Sparta', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'NG', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'CCC', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'FAIL', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'GLoF', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'GLoF', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'NpO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'TKTB', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'TCTB', to: 'TKTB', arrows: 'to', ...pixel },
  { from: 'NpO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'MHA', to: 'DS', arrows: 'to', ...pixel },
  { from: 'MHA', to: 'DW', arrows: 'to', ...pixel },
]

export const july19 = [
  { from: 'FTW', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'FTW', to: 'DS', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DS', arrows: 'to', ...pixel },
  { from: 'CLAWS', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'Legion', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Sparta', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'EO', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'NG', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'CCC', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'FAIL', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'GLoF', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'GLoF', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'NpO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'TKTB', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'TCTB', to: 'TKTB', arrows: 'to', ...pixel },
  { from: 'NpO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'MHA', to: 'DS', arrows: 'to', ...pixel },
  { from: 'MHA', to: 'DW', arrows: 'to', ...pixel },
  { from: 'OBR', to: 'DS', arrows: 'to' },
  { from: 'OBR', to: 'DW', arrows: 'to' },
  { from: 'OBR', to: 'DBDC', arrows: 'to' },
  { from: 'OG', to: 'DS', arrows: 'to' },
  { from: 'OG', to: 'DW', arrows: 'to' },
  { from: 'OG', to: 'DBDC', arrows: 'to' },
  { from: 'NG', to: 'TKTB', arrows: 'to', ...pixel  },
]

export const july21 = [
  { from: 'FTW', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'FTW', to: 'DS', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DS', arrows: 'to', ...pixel },
  { from: 'CLAWS', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'Legion', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Sparta', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'EO', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'NG', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'CCC', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'FAIL', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'GLoF', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'GLoF', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'NpO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'TKTB', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'TCTB', to: 'TKTB', arrows: 'to', ...pixel },
  { from: 'NpO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'MHA', to: 'DS', arrows: 'to', ...pixel },
  { from: 'MHA', to: 'DW', arrows: 'to', ...pixel },
  { from: 'OBR', to: 'DS', arrows: 'to' },
  { from: 'OBR', to: 'DW', arrows: 'to' },
  { from: 'OBR', to: 'DBDC', arrows: 'to' },
  { from: 'OG', to: 'DS', arrows: 'to' },
  { from: 'OG', to: 'DW', arrows: 'to' },
  { from: 'OG', to: 'DBDC', arrows: 'to' },
  { from: 'NG', to: 'TKTB', arrows: 'to', ...pixel  },
  { from: 'TLR', to: 'IRON', arrows: 'to', ...pixel },
]

export const july22 = [
  { from: 'FTW', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'FTW', to: 'DS', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DS', arrows: 'to', ...pixel },
  { from: 'CLAWS', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'Legion', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Sparta', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'EO', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'NG', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'CCC', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'FAIL', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'GLoF', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'GLoF', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'NpO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'TKTB', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'TCTB', to: 'TKTB', arrows: 'to', ...pixel },
  { from: 'NpO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'MHA', to: 'DS', arrows: 'to', ...pixel },
  { from: 'MHA', to: 'DW', arrows: 'to', ...pixel },
  { from: 'OBR', to: 'DS', arrows: 'to' },
  { from: 'OBR', to: 'DW', arrows: 'to' },
  { from: 'OBR', to: 'DBDC', arrows: 'to' },
  { from: 'OG', to: 'DS', arrows: 'to' },
  { from: 'OG', to: 'DW', arrows: 'to' },
  { from: 'OG', to: 'DBDC', arrows: 'to' },
  { from: 'NG', to: 'TKTB', arrows: 'to', ...pixel  },
  { from: 'TLR', to: 'IRON', arrows: 'to', ...pixel },
  { from: 'Mongols', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Mongols', to: 'IRON', arrows: 'to', ...pixel },
  { from: 'VE', to: 'NG', arrows: 'to', ...hegemony },
]

export const july24 = [
  { from: 'FTW', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'FTW', to: 'DS', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DS', arrows: 'to', ...pixel },
  { from: 'CLAWS', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'Legion', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Sparta', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'EO', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'NG', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'CCC', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'FAIL', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'GLoF', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'GLoF', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'NpO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'TKTB', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'TCTB', to: 'TKTB', arrows: 'to', ...pixel },
  { from: 'NpO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'MHA', to: 'DS', arrows: 'to', ...pixel },
  { from: 'MHA', to: 'DW', arrows: 'to', ...pixel },
  { from: 'OBR', to: 'DS', arrows: 'to' },
  { from: 'OBR', to: 'DW', arrows: 'to' },
  { from: 'OBR', to: 'DBDC', arrows: 'to' },
  { from: 'OG', to: 'DS', arrows: 'to' },
  { from: 'OG', to: 'DW', arrows: 'to' },
  { from: 'OG', to: 'DBDC', arrows: 'to' },
  { from: 'NG', to: 'TKTB', arrows: 'to', ...pixel  },
  { from: 'TLR', to: 'IRON', arrows: 'to', ...pixel },
  { from: 'Mongols', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Mongols', to: 'IRON', arrows: 'to', ...pixel },
  { from: 'VE', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'Ouroboros', to: 'CLAWS', arrows: 'to' },
  { from: 'NoR', to: 'VE', arrows: 'to', ...pixel },
]

export const july25 = [
  { from: 'FTW', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'FTW', to: 'DS', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DS', arrows: 'to', ...pixel },
  { from: 'CLAWS', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'Legion', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Sparta', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'EO', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'NG', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'CCC', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'FAIL', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'GLoF', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'GLoF', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'NpO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'TKTB', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'TCTB', to: 'TKTB', arrows: 'to', ...pixel },
  { from: 'NpO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'MHA', to: 'DS', arrows: 'to', ...pixel },
  { from: 'MHA', to: 'DW', arrows: 'to', ...pixel },
  { from: 'OBR', to: 'DS', arrows: 'to' },
  { from: 'OBR', to: 'DW', arrows: 'to' },
  { from: 'OBR', to: 'DBDC', arrows: 'to' },
  { from: 'OG', to: 'DS', arrows: 'to' },
  { from: 'OG', to: 'DW', arrows: 'to' },
  { from: 'OG', to: 'DBDC', arrows: 'to' },
  { from: 'NG', to: 'TKTB', arrows: 'to', ...pixel  },
  { from: 'TLR', to: 'IRON', arrows: 'to', ...pixel },
  { from: 'Mongols', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Mongols', to: 'IRON', arrows: 'to', ...pixel },
  { from: 'VE', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'Ouroboros', to: 'CLAWS', arrows: 'to' },
  { from: 'NoR', to: 'VE', arrows: 'to', ...pixel },
  { from: 'KoRT', to: 'CCC', arrows: 'to', ...hegemony },
]

export const july29 = [
  { from: 'FTW', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'FTW', to: 'DS', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DS', arrows: 'to', ...pixel },
  { from: 'CLAWS', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'Legion', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Sparta', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'EO', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'NG', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'CCC', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'FAIL', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'GLoF', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'GLoF', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'NpO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'TKTB', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'TCTB', to: 'TKTB', arrows: 'to', ...pixel },
  { from: 'NpO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'MHA', to: 'DS', arrows: 'to', ...pixel },
  { from: 'MHA', to: 'DW', arrows: 'to', ...pixel },
  { from: 'OBR', to: 'DS', arrows: 'to' },
  { from: 'OBR', to: 'DW', arrows: 'to' },
  { from: 'OBR', to: 'DBDC', arrows: 'to' },
  { from: 'OG', to: 'DS', arrows: 'to' },
  { from: 'OG', to: 'DW', arrows: 'to' },
  { from: 'OG', to: 'DBDC', arrows: 'to' },
  { from: 'NG', to: 'TKTB', arrows: 'to', ...pixel  },
  { from: 'TLR', to: 'IRON', arrows: 'to', ...pixel },
  { from: 'Mongols', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Mongols', to: 'IRON', arrows: 'to', ...pixel },
  { from: 'VE', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'Ouroboros', to: 'CLAWS', arrows: 'to' },
  { from: 'NoR', to: 'VE', arrows: 'to', ...pixel },
  { from: 'KoRT', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'PGS', to: 'CCC', arrows: 'to', ...hegemony },
  { from: `"Kashmir"`, to: 'NPO', arrows: 'to', ...pixel },
  { from: `"Kashmir"`, to: 'IRON', arrows: 'to', ...pixel },
  { from: `"Kashmir"`, to: 'VE', arrows: 'to', ...pixel },
  { from: `"Kashmir"`, to: 'TKTB', arrows: 'to', ...pixel },
]

export const july30 = [
  { from: 'FTW', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'FTW', to: 'DS', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DS', arrows: 'to', ...pixel },
  { from: 'CLAWS', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'Legion', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Sparta', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'EO', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'NG', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'CCC', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'FAIL', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'GLoF', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'GLoF', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'NpO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'TKTB', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'TCTB', to: 'TKTB', arrows: 'to', ...pixel },
  { from: 'NpO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'MHA', to: 'DS', arrows: 'to', ...pixel },
  { from: 'MHA', to: 'DW', arrows: 'to', ...pixel },
  { from: 'OBR', to: 'DS', arrows: 'to' },
  { from: 'OBR', to: 'DW', arrows: 'to' },
  { from: 'OBR', to: 'DBDC', arrows: 'to' },
  { from: 'OG', to: 'DS', arrows: 'to' },
  { from: 'OG', to: 'DW', arrows: 'to' },
  { from: 'OG', to: 'DBDC', arrows: 'to' },
  { from: 'NG', to: 'TKTB', arrows: 'to', ...pixel  },
  { from: 'TLR', to: 'IRON', arrows: 'to', ...pixel },
  { from: 'Mongols', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Mongols', to: 'IRON', arrows: 'to', ...pixel },
  { from: 'VE', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'Ouroboros', to: 'CLAWS', arrows: 'to' },
  { from: 'NoR', to: 'VE', arrows: 'to', ...pixel },
  { from: 'KoRT', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'PGS', to: 'CCC', arrows: 'to', ...hegemony },
  { from: `"Kashmir"`, to: 'NPO', arrows: 'to', ...pixel },
  { from: `"Kashmir"`, to: 'IRON', arrows: 'to', ...pixel },
  { from: `"Kashmir"`, to: 'VE', arrows: 'to', ...pixel },
  { from: `"Kashmir"`, to: 'TKTB', arrows: 'to', ...pixel },
  { from: 'WWF', to: 'OBR', arrows: 'to' },
  { from: 'WWF', to: 'OG', arrows: 'to' },
]

export const july31 = [
  { from: 'FTW', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'FTW', to: 'DS', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DS', arrows: 'to', ...pixel },
  { from: 'CLAWS', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'Legion', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Sparta', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'EO', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'NG', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'CCC', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'FAIL', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'GLoF', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'GLoF', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'NpO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'TKTB', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'TCTB', to: 'TKTB', arrows: 'to', ...pixel },
  { from: 'NpO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'MHA', to: 'DS', arrows: 'to', ...pixel },
  { from: 'MHA', to: 'DW', arrows: 'to', ...pixel },
  { from: 'OBR', to: 'DS', arrows: 'to' },
  { from: 'OBR', to: 'DW', arrows: 'to' },
  { from: 'OBR', to: 'DBDC', arrows: 'to' },
  { from: 'OG', to: 'DS', arrows: 'to' },
  { from: 'OG', to: 'DW', arrows: 'to' },
  { from: 'OG', to: 'DBDC', arrows: 'to' },
  { from: 'NG', to: 'TKTB', arrows: 'to', ...pixel  },
  { from: 'TLR', to: 'IRON', arrows: 'to', ...pixel },
  { from: 'Mongols', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Mongols', to: 'IRON', arrows: 'to', ...pixel },
  { from: 'VE', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'Ouroboros', to: 'CLAWS', arrows: 'to' },
  { from: 'NoR', to: 'VE', arrows: 'to', ...pixel },
  { from: 'KoRT', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'PGS', to: 'CCC', arrows: 'to', ...hegemony },
  { from: `"Kashmir"`, to: 'NPO', arrows: 'to', ...pixel },
  { from: `"Kashmir"`, to: 'IRON', arrows: 'to', ...pixel },
  { from: `"Kashmir"`, to: 'VE', arrows: 'to', ...pixel },
  { from: `"Kashmir"`, to: 'TKTB', arrows: 'to', ...pixel },
  { from: 'WWF', to: 'OBR', arrows: 'to' },
  { from: 'WWF', to: 'OG', arrows: 'to' },
]

export const aug12 = [
  { from: 'FTW', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'FTW', to: 'DS', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DS', arrows: 'to', ...pixel },
  { from: 'CLAWS', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'Legion', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Sparta', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'EO', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'NG', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'CCC', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'FAIL', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'GLoF', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'GLoF', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'NpO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'TKTB', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'TCTB', to: 'TKTB', arrows: 'to', ...pixel },
  { from: 'NpO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'MHA', to: 'DS', arrows: 'to', ...pixel },
  { from: 'MHA', to: 'DW', arrows: 'to', ...pixel },
  { from: 'OBR', to: 'DS', arrows: 'to' },
  { from: 'OBR', to: 'DW', arrows: 'to' },
  { from: 'OBR', to: 'DBDC', arrows: 'to' },
  { from: 'OG', to: 'DS', arrows: 'to' },
  { from: 'OG', to: 'DW', arrows: 'to' },
  { from: 'OG', to: 'DBDC', arrows: 'to' },
  { from: 'NG', to: 'TKTB', arrows: 'to', ...pixel  },
  { from: 'TLR', to: 'IRON', arrows: 'to', ...pixel },
  { from: 'Mongols', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Mongols', to: 'IRON', arrows: 'to', ...pixel },
  { from: 'VE', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'Ouroboros', to: 'CLAWS', arrows: 'to' },
  { from: 'NoR', to: 'VE', arrows: 'to', ...pixel },
  { from: 'KoRT', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'PGS', to: 'CCC', arrows: 'to', ...hegemony },
  { from: `"Kashmir"`, to: 'NPO', arrows: 'to', ...pixel },
  { from: `"Kashmir"`, to: 'IRON', arrows: 'to', ...pixel },
  { from: `"Kashmir"`, to: 'VE', arrows: 'to', ...pixel },
  { from: `"Kashmir"`, to: 'TKTB', arrows: 'to', ...pixel },
  { from: 'WWF', to: 'OBR', arrows: 'to' },
  { from: 'WWF', to: 'OG', arrows: 'to' },
  { from: `Fark`, to: 'VE', arrows: 'to', ...pixel },
  { from: `FAN`, to: 'VE', arrows: 'to', ...pixel },
]

export const aug14 = [
  { from: 'FTW', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'FTW', to: 'DS', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DS', arrows: 'to', ...pixel },
  { from: 'CLAWS', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'Legion', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Sparta', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'EO', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'NG', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'CCC', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'FAIL', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'GLoF', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'GLoF', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'NpO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'TKTB', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'TCTB', to: 'TKTB', arrows: 'to', ...pixel },
  { from: 'NpO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'MHA', to: 'DS', arrows: 'to', ...pixel },
  { from: 'MHA', to: 'DW', arrows: 'to', ...pixel },
  { from: 'OBR', to: 'DS', arrows: 'to' },
  { from: 'OBR', to: 'DW', arrows: 'to' },
  { from: 'OBR', to: 'DBDC', arrows: 'to' },
  { from: 'OG', to: 'DS', arrows: 'to' },
  { from: 'OG', to: 'DW', arrows: 'to' },
  { from: 'OG', to: 'DBDC', arrows: 'to' },
  { from: 'NG', to: 'TKTB', arrows: 'to', ...pixel  },
  { from: 'TLR', to: 'IRON', arrows: 'to', ...pixel },
  { from: 'Mongols', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Mongols', to: 'IRON', arrows: 'to', ...pixel },
  { from: 'VE', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'Ouroboros', to: 'CLAWS', arrows: 'to' },
  { from: 'NoR', to: 'VE', arrows: 'to', ...pixel },
  { from: 'KoRT', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'PGS', to: 'CCC', arrows: 'to', ...hegemony },
  { from: `"Kashmir"`, to: 'NPO', arrows: 'to', ...pixel },
  { from: `"Kashmir"`, to: 'IRON', arrows: 'to', ...pixel },
  { from: `"Kashmir"`, to: 'VE', arrows: 'to', ...pixel },
  { from: `"Kashmir"`, to: 'TKTB', arrows: 'to', ...pixel },
  { from: 'WWF', to: 'OBR', arrows: 'to' },
  { from: 'WWF', to: 'OG', arrows: 'to' },
  { from: `Fark`, to: 'VE', arrows: 'to', ...pixel },
  { from: `FAN`, to: 'VE', arrows: 'to', ...pixel },
  { from: `RIA`, to: 'VE', arrows: 'to', ...pixel },
]

export const aug16 = [
  { from: 'FTW', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'FTW', to: 'DS', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DBDC', arrows: 'to', ...pixel },
  { from: 'NATO', to: 'DS', arrows: 'to', ...pixel },
  { from: 'CLAWS', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'NPO', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'DT', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'Legion', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Sparta', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'EO', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'NG', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'CCC', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'FAIL', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'GLoF', to: 'FTW', arrows: 'to', ...hegemony },
  { from: 'GLoF', to: 'NATO', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'IRON', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'NpO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'CLAWS', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'TKTB', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'GATO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'Argent', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'TCTB', to: 'TKTB', arrows: 'to', ...pixel },
  { from: 'NpO', to: 'Sparta', arrows: 'to', ...hegemony },
  { from: 'MHA', to: 'DS', arrows: 'to', ...pixel },
  { from: 'MHA', to: 'DW', arrows: 'to', ...pixel },
  { from: 'OBR', to: 'DS', arrows: 'to' },
  { from: 'OBR', to: 'DW', arrows: 'to' },
  { from: 'OBR', to: 'DBDC', arrows: 'to' },
  { from: 'OG', to: 'DS', arrows: 'to' },
  { from: 'OG', to: 'DW', arrows: 'to' },
  { from: 'OG', to: 'DBDC', arrows: 'to' },
  { from: 'NG', to: 'TKTB', arrows: 'to', ...pixel  },
  { from: 'TLR', to: 'IRON', arrows: 'to', ...pixel },
  { from: 'Mongols', to: 'NPO', arrows: 'to', ...pixel },
  { from: 'Mongols', to: 'IRON', arrows: 'to', ...pixel },
  { from: 'VE', to: 'NG', arrows: 'to', ...hegemony },
  { from: 'Ouroboros', to: 'CLAWS', arrows: 'to' },
  { from: 'NoR', to: 'VE', arrows: 'to', ...pixel },
  { from: 'KoRT', to: 'CCC', arrows: 'to', ...hegemony },
  { from: 'PGS', to: 'CCC', arrows: 'to', ...hegemony },
  { from: `"Kashmir"`, to: 'NPO', arrows: 'to', ...pixel },
  { from: `"Kashmir"`, to: 'IRON', arrows: 'to', ...pixel },
  { from: `"Kashmir"`, to: 'VE', arrows: 'to', ...pixel },
  { from: `"Kashmir"`, to: 'TKTB', arrows: 'to', ...pixel },
  { from: 'WWF', to: 'OBR', arrows: 'to' },
  { from: 'WWF', to: 'OG', arrows: 'to' },
  { from: `Fark`, to: 'VE', arrows: 'to', ...pixel },
  { from: `FAN`, to: 'VE', arrows: 'to', ...pixel },
  { from: `RIA`, to: 'VE', arrows: 'to', ...pixel },
  { from: 'NpO', to: 'FAN', arrows: 'to', ...hegemony },
]