export const total = [
  { id: 'NPO', label: "NPO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013124311AM.jpg', group: 'Oculus' },
  { id: 'IRON', label: "IRON", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201310141AM.jpg', group: 'Oculus' },
  { id: 'FTW', label: "FTW", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1302020121525PM.jpg', groups: ['White', 'RFD'] },
  { id: 'CLAWS', label: "CLAWS", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201830850AM.jpg', font: { color: "pink", background: 'black' }, group: 'RFI' },
  { id: 'Legion', label: "Legion", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201392349PM.jpg', group: 'RFI' },
  { id: 'NpO', label: "NpO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags530201495610AM.jpg', groups: ['Oculus', 'Dos Equis', 'Bluetooth'] },
  { id: 'NG', label: "NG", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201371848AM.jpg', group: 'RFD' },
  { id: 'KoRT', label: "KoRT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201343715PM.jpg'},
  { id: 'GATO', label: "GATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013114249PM.jpg', groups: ['RFI', 'Butt'] },
  { id: 'NATO', label: "NATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201385607PM.jpg', group: 'Bluetooth' },
  { id: 'DT', label: "DT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201335929PM.jpg', group: 'Oculus' },
  { id: 'VE', label: "VE", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags323201582938PM.jpg', group: 'Oculus'},
  { id: 'Argent', label: "Argent", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags87202074410PM.jpg', group: 'RFI' },
  { id: 'Sparta', label: "Sparta", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013125313AM.jpg' },
  { id: 'DBDC', label: "DBDC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1219201463233PM.jpg' },
  { id: 'CCC', label: "CCC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags413201494440PM.jpg', groups: ['White', 'RFD'] },
  { id: 'OBR', label: "OBR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags162018123736PM.jpg' },
  { id: 'MHA', label: "MHA", shape: 'image', image: 'https://www.cybernations.net/images/flags/Custom48.png', group: 'Dos Equis' },
  { id: 'TLR', label: "TLR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags6242014114032AM.jpg' },
  { id: 'GLoF', label: "GLoF", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201361153PM.jpg', group: 'Oculus' },
  { id: 'NoR', label: "NoR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags22201861445PM.jpg' },
  { id: 'Ouroboros', label: "Ouroboros", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/2/27/Flag_of_Ouroboros.png' },
  { id: 'TKTB', label: "TKTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/d/d7/Flag_of_TKTB.png' },
  { id: 'OG', label: "OG", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/62/Og.jpg' },
  { id: 'DS', label: "DS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/c/c9/Doom_Squad.png' },
  { id: 'DW', label: "DW", shape: 'image', image: 'https://www.cybernations.net/images/flags/Custom132.png' },
  { id: 'FAIL', label: "FAIL", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/6a/Failmark4.jpg' },
  { id: 'EO', label: "EO", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/8/87/Flag_of_the_Ender_Order.jpg' },
  { id: 'TCTB', label: "TCTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/9/90/TCTB_Flag.png' },
  { id: 'Mongols', label: "Mongols", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/3/32/Flag_of_MONGOLS.jpg' },
  { id: 'PGS', label: "PGS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/8/81/Flag_of_PGS.jpg', groups: 'Butt' },
  { id: `"Kashmir"`, label: `"Kashmir"`, shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/a/ab/Official_Flag_of_Kashmir.gif' },
  { id: 'WWF', label: "WWF", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags122201674402AM.jpg' },
  { id: 'Fark', label: "Fark", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201344446PM.jpg', group: 'Dos Equis' },
  { id: 'FAN', label: "FAN", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201380131PM.jpg' },
]

export const june25 = [
  { id: 'FTW', label: "FTW", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1302020121525PM.jpg', groups: ['White', 'RFD'] },
  { id: 'NATO', label: "NATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201385607PM.jpg', group: 'Bluetooth' },
  { id: 'DBDC', label: "DBDC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1219201463233PM.jpg' },
  { id: 'DS', label: "DS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/c/c9/Doom_Squad.png' },
]

export const june26 = [
  { id: 'FTW', label: "FTW", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1302020121525PM.jpg', groups: ['White', 'RFD'] },
  { id: 'NATO', label: "NATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201385607PM.jpg', group: 'Bluetooth' },
  { id: 'DBDC', label: "DBDC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1219201463233PM.jpg' },
  { id: 'DS', label: "DS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/c/c9/Doom_Squad.png' },
  { id: 'CLAWS', label: "CLAWS", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201830850AM.jpg', font: { color: "pink", background: 'black' }, group: 'RFI' },
]

export const june28 = [
  { id: 'FTW', label: "FTW", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1302020121525PM.jpg', groups: ['White', 'RFD'] },
  { id: 'NATO', label: "NATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201385607PM.jpg', group: 'Bluetooth' },
  { id: 'DBDC', label: "DBDC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1219201463233PM.jpg' },
  { id: 'DS', label: "DS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/c/c9/Doom_Squad.png' },
  { id: 'CLAWS', label: "CLAWS", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201830850AM.jpg', font: { color: "pink", background: 'black' }, group: 'RFI' },
  { id: 'NPO', label: "NPO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013124311AM.jpg', group: 'Oculus' },
  { id: 'DT', label: "DT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201335929PM.jpg', group: 'Oculus' },
]

export const july1 = [
  { id: 'FTW', label: "FTW", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1302020121525PM.jpg', groups: ['White', 'RFD'] },
  { id: 'NATO', label: "NATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201385607PM.jpg', group: 'Bluetooth' },
  { id: 'DBDC', label: "DBDC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1219201463233PM.jpg' },
  { id: 'DS', label: "DS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/c/c9/Doom_Squad.png' },
  { id: 'CLAWS', label: "CLAWS", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201830850AM.jpg', font: { color: "pink", background: 'black' }, group: 'RFI' },
  { id: 'NPO', label: "NPO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013124311AM.jpg', group: 'Oculus' },
  { id: 'DT', label: "DT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201335929PM.jpg', group: 'Oculus' },
  { id: 'Legion', label: "Legion", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201392349PM.jpg', group: 'RFI' },
]

export const july3 = [
  { id: 'FTW', label: "FTW", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1302020121525PM.jpg', groups: ['White', 'RFD'] },
  { id: 'NATO', label: "NATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201385607PM.jpg', group: 'Bluetooth' },
  { id: 'DBDC', label: "DBDC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1219201463233PM.jpg' },
  { id: 'DS', label: "DS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/c/c9/Doom_Squad.png' },
  { id: 'CLAWS', label: "CLAWS", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201830850AM.jpg', font: { color: "pink", background: 'black' }, group: 'RFI' },
  { id: 'NPO', label: "NPO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013124311AM.jpg', group: 'Oculus' },
  { id: 'DT', label: "DT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201335929PM.jpg', group: 'Oculus' },
  { id: 'Legion', label: "Legion", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201392349PM.jpg', group: 'RFI' },
  { id: 'Sparta', label: "Sparta", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013125313AM.jpg' },
]

export const july5 = [
  { id: 'FTW', label: "FTW", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1302020121525PM.jpg', groups: ['White', 'RFD'] },
  { id: 'NATO', label: "NATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201385607PM.jpg', group: 'Bluetooth' },
  { id: 'DBDC', label: "DBDC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1219201463233PM.jpg' },
  { id: 'DS', label: "DS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/c/c9/Doom_Squad.png' },
  { id: 'CLAWS', label: "CLAWS", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201830850AM.jpg', font: { color: "pink", background: 'black' }, group: 'RFI' },
  { id: 'NPO', label: "NPO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013124311AM.jpg', group: 'Oculus' },
  { id: 'DT', label: "DT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201335929PM.jpg', group: 'Oculus' },
  { id: 'Legion', label: "Legion", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201392349PM.jpg', group: 'RFI' },
  { id: 'Sparta', label: "Sparta", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013125313AM.jpg' },
  { id: 'FAIL', label: "FAIL", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/6a/Failmark4.jpg' },
  { id: 'EO', label: "EO", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/8/87/Flag_of_the_Ender_Order.jpg' },
  { id: 'NG', label: "NG", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201371848AM.jpg', group: 'RFD' },
  { id: 'CCC', label: "CCC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags413201494440PM.jpg', groups: ['White', 'RFD'] },
]

export const july6 = [
  { id: 'FTW', label: "FTW", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1302020121525PM.jpg', groups: ['White', 'RFD'] },
  { id: 'NATO', label: "NATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201385607PM.jpg', group: 'Bluetooth' },
  { id: 'DBDC', label: "DBDC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1219201463233PM.jpg' },
  { id: 'DS', label: "DS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/c/c9/Doom_Squad.png' },
  { id: 'CLAWS', label: "CLAWS", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201830850AM.jpg', font: { color: "pink", background: 'black' }, group: 'RFI' },
  { id: 'NPO', label: "NPO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013124311AM.jpg', group: 'Oculus' },
  { id: 'DT', label: "DT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201335929PM.jpg', group: 'Oculus' },
  { id: 'Legion', label: "Legion", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201392349PM.jpg', group: 'RFI' },
  { id: 'Sparta', label: "Sparta", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013125313AM.jpg' },
  { id: 'FAIL', label: "FAIL", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/6a/Failmark4.jpg' },
  { id: 'EO', label: "EO", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/8/87/Flag_of_the_Ender_Order.jpg' },
  { id: 'NG', label: "NG", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201371848AM.jpg', group: 'RFD' },
  { id: 'CCC', label: "CCC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags413201494440PM.jpg', groups: ['White', 'RFD'] },
  { id: 'IRON', label: "IRON", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201310141AM.jpg', group: 'Oculus' },
  { id: 'NpO', label: "NpO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags530201495610AM.jpg', groups: ['Oculus', 'Dos Equis', 'Bluetooth'] },
  { id: 'TKTB', label: "TKTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/d/d7/Flag_of_TKTB.png' },
  { id: 'GLoF', label: "GLoF", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201361153PM.jpg', group: 'Oculus' },
]

export const july7 = [
  { id: 'FTW', label: "FTW", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1302020121525PM.jpg', groups: ['White', 'RFD'] },
  { id: 'NATO', label: "NATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201385607PM.jpg', group: 'Bluetooth' },
  { id: 'DBDC', label: "DBDC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1219201463233PM.jpg' },
  { id: 'DS', label: "DS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/c/c9/Doom_Squad.png' },
  { id: 'CLAWS', label: "CLAWS", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201830850AM.jpg', font: { color: "pink", background: 'black' }, group: 'RFI' },
  { id: 'NPO', label: "NPO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013124311AM.jpg', group: 'Oculus' },
  { id: 'DT', label: "DT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201335929PM.jpg', group: 'Oculus' },
  { id: 'Legion', label: "Legion", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201392349PM.jpg', group: 'RFI' },
  { id: 'Sparta', label: "Sparta", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013125313AM.jpg' },
  { id: 'FAIL', label: "FAIL", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/6a/Failmark4.jpg' },
  { id: 'EO', label: "EO", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/8/87/Flag_of_the_Ender_Order.jpg' },
  { id: 'NG', label: "NG", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201371848AM.jpg', group: 'RFD' },
  { id: 'CCC', label: "CCC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags413201494440PM.jpg', groups: ['White', 'RFD'] },
  { id: 'IRON', label: "IRON", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201310141AM.jpg', group: 'Oculus' },
  { id: 'NpO', label: "NpO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags530201495610AM.jpg', groups: ['Oculus', 'Dos Equis', 'Bluetooth'] },
  { id: 'TKTB', label: "TKTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/d/d7/Flag_of_TKTB.png' },
  { id: 'GLoF', label: "GLoF", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201361153PM.jpg', group: 'Oculus' },
  { id: 'GATO', label: "GATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013114249PM.jpg', groups: ['RFI', 'Butt'] },
  { id: 'Argent', label: "Argent", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags87202074410PM.jpg', group: 'RFI' },
]

export const july9 = [
  { id: 'FTW', label: "FTW", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1302020121525PM.jpg', groups: ['White', 'RFD'] },
  { id: 'NATO', label: "NATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201385607PM.jpg', group: 'Bluetooth' },
  { id: 'DBDC', label: "DBDC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1219201463233PM.jpg' },
  { id: 'DS', label: "DS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/c/c9/Doom_Squad.png' },
  { id: 'CLAWS', label: "CLAWS", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201830850AM.jpg', font: { color: "pink", background: 'black' }, group: 'RFI' },
  { id: 'NPO', label: "NPO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013124311AM.jpg', group: 'Oculus' },
  { id: 'DT', label: "DT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201335929PM.jpg', group: 'Oculus' },
  { id: 'Legion', label: "Legion", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201392349PM.jpg', group: 'RFI' },
  { id: 'Sparta', label: "Sparta", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013125313AM.jpg' },
  { id: 'FAIL', label: "FAIL", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/6a/Failmark4.jpg' },
  { id: 'EO', label: "EO", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/8/87/Flag_of_the_Ender_Order.jpg' },
  { id: 'NG', label: "NG", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201371848AM.jpg', group: 'RFD' },
  { id: 'CCC', label: "CCC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags413201494440PM.jpg', groups: ['White', 'RFD'] },
  { id: 'IRON', label: "IRON", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201310141AM.jpg', group: 'Oculus' },
  { id: 'NpO', label: "NpO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags530201495610AM.jpg', groups: ['Oculus', 'Dos Equis', 'Bluetooth'] },
  { id: 'TKTB', label: "TKTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/d/d7/Flag_of_TKTB.png' },
  { id: 'GLoF', label: "GLoF", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201361153PM.jpg', group: 'Oculus' },
  { id: 'GATO', label: "GATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013114249PM.jpg', groups: ['RFI', 'Butt'] },
  { id: 'Argent', label: "Argent", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags87202074410PM.jpg', group: 'RFI' },
]

export const july15 = [
  { id: 'FTW', label: "FTW", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1302020121525PM.jpg', groups: ['White', 'RFD'] },
  { id: 'NATO', label: "NATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201385607PM.jpg', group: 'Bluetooth' },
  { id: 'DBDC', label: "DBDC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1219201463233PM.jpg' },
  { id: 'DS', label: "DS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/c/c9/Doom_Squad.png' },
  { id: 'CLAWS', label: "CLAWS", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201830850AM.jpg', font: { color: "pink", background: 'black' }, group: 'RFI' },
  { id: 'NPO', label: "NPO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013124311AM.jpg', group: 'Oculus' },
  { id: 'DT', label: "DT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201335929PM.jpg', group: 'Oculus' },
  { id: 'Legion', label: "Legion", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201392349PM.jpg', group: 'RFI' },
  { id: 'Sparta', label: "Sparta", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013125313AM.jpg' },
  { id: 'FAIL', label: "FAIL", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/6a/Failmark4.jpg' },
  { id: 'EO', label: "EO", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/8/87/Flag_of_the_Ender_Order.jpg' },
  { id: 'NG', label: "NG", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201371848AM.jpg', group: 'RFD' },
  { id: 'CCC', label: "CCC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags413201494440PM.jpg', groups: ['White', 'RFD'] },
  { id: 'IRON', label: "IRON", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201310141AM.jpg', group: 'Oculus' },
  { id: 'NpO', label: "NpO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags530201495610AM.jpg', groups: ['Oculus', 'Dos Equis', 'Bluetooth'] },
  { id: 'TKTB', label: "TKTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/d/d7/Flag_of_TKTB.png' },
  { id: 'GLoF', label: "GLoF", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201361153PM.jpg', group: 'Oculus' },
  { id: 'GATO', label: "GATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013114249PM.jpg', groups: ['RFI', 'Butt'] },
  { id: 'Argent', label: "Argent", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags87202074410PM.jpg', group: 'RFI' },
  { id: 'TCTB', label: "TCTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/9/90/TCTB_Flag.png' },
]

export const july17 = [
  { id: 'FTW', label: "FTW", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1302020121525PM.jpg', groups: ['White', 'RFD'] },
  { id: 'NATO', label: "NATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201385607PM.jpg', group: 'Bluetooth' },
  { id: 'DBDC', label: "DBDC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1219201463233PM.jpg' },
  { id: 'DS', label: "DS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/c/c9/Doom_Squad.png' },
  { id: 'CLAWS', label: "CLAWS", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201830850AM.jpg', font: { color: "pink", background: 'black' }, group: 'RFI' },
  { id: 'NPO', label: "NPO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013124311AM.jpg', group: 'Oculus' },
  { id: 'DT', label: "DT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201335929PM.jpg', group: 'Oculus' },
  { id: 'Legion', label: "Legion", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201392349PM.jpg', group: 'RFI' },
  { id: 'Sparta', label: "Sparta", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013125313AM.jpg' },
  { id: 'FAIL', label: "FAIL", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/6a/Failmark4.jpg' },
  { id: 'NG', label: "NG", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201371848AM.jpg', group: 'RFD' },
  { id: 'CCC', label: "CCC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags413201494440PM.jpg', groups: ['White', 'RFD'] },
  { id: 'IRON', label: "IRON", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201310141AM.jpg', group: 'Oculus' },
  { id: 'NpO', label: "NpO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags530201495610AM.jpg', groups: ['Oculus', 'Dos Equis', 'Bluetooth'] },
  { id: 'TKTB', label: "TKTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/d/d7/Flag_of_TKTB.png' },
  { id: 'GLoF', label: "GLoF", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201361153PM.jpg', group: 'Oculus' },
  { id: 'GATO', label: "GATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013114249PM.jpg', groups: ['RFI', 'Butt'] },
  { id: 'Argent', label: "Argent", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags87202074410PM.jpg', group: 'RFI' },
  { id: 'TCTB', label: "TCTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/9/90/TCTB_Flag.png' },
  { id: 'MHA', label: "MHA", shape: 'image', image: 'https://www.cybernations.net/images/flags/Custom48.png', group: 'Dos Equis' },
]

export const july19 = [
  { id: 'FTW', label: "FTW", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1302020121525PM.jpg', groups: ['White', 'RFD'] },
  { id: 'NATO', label: "NATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201385607PM.jpg', group: 'Bluetooth' },
  { id: 'DBDC', label: "DBDC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1219201463233PM.jpg' },
  { id: 'DS', label: "DS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/c/c9/Doom_Squad.png' },
  { id: 'CLAWS', label: "CLAWS", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201830850AM.jpg', font: { color: "pink", background: 'black' }, group: 'RFI' },
  { id: 'NPO', label: "NPO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013124311AM.jpg', group: 'Oculus' },
  { id: 'DT', label: "DT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201335929PM.jpg', group: 'Oculus' },
  { id: 'Legion', label: "Legion", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201392349PM.jpg', group: 'RFI' },
  { id: 'Sparta', label: "Sparta", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013125313AM.jpg' },
  { id: 'FAIL', label: "FAIL", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/6a/Failmark4.jpg' },
  { id: 'NG', label: "NG", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201371848AM.jpg', group: 'RFD' },
  { id: 'CCC', label: "CCC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags413201494440PM.jpg', groups: ['White', 'RFD'] },
  { id: 'IRON', label: "IRON", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201310141AM.jpg', group: 'Oculus' },
  { id: 'NpO', label: "NpO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags530201495610AM.jpg', groups: ['Oculus', 'Dos Equis', 'Bluetooth'] },
  { id: 'TKTB', label: "TKTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/d/d7/Flag_of_TKTB.png' },
  { id: 'GLoF', label: "GLoF", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201361153PM.jpg', group: 'Oculus' },
  { id: 'GATO', label: "GATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013114249PM.jpg', groups: ['RFI', 'Butt'] },
  { id: 'Argent', label: "Argent", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags87202074410PM.jpg', group: 'RFI' },
  { id: 'TCTB', label: "TCTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/9/90/TCTB_Flag.png' },
  { id: 'MHA', label: "MHA", shape: 'image', image: 'https://www.cybernations.net/images/flags/Custom48.png', group: 'Dos Equis' },
  { id: 'OBR', label: "OBR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags162018123736PM.jpg' },
  { id: 'OG', label: "OG", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/62/Og.jpg' },
]

export const july21 = [
  { id: 'FTW', label: "FTW", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1302020121525PM.jpg', groups: ['White', 'RFD'] },
  { id: 'NATO', label: "NATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201385607PM.jpg', group: 'Bluetooth' },
  { id: 'DBDC', label: "DBDC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1219201463233PM.jpg' },
  { id: 'DS', label: "DS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/c/c9/Doom_Squad.png' },
  { id: 'CLAWS', label: "CLAWS", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201830850AM.jpg', font: { color: "pink", background: 'black' }, group: 'RFI' },
  { id: 'NPO', label: "NPO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013124311AM.jpg', group: 'Oculus' },
  { id: 'DT', label: "DT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201335929PM.jpg', group: 'Oculus' },
  { id: 'Legion', label: "Legion", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201392349PM.jpg', group: 'RFI' },
  { id: 'Sparta', label: "Sparta", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013125313AM.jpg' },
  { id: 'FAIL', label: "FAIL", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/6a/Failmark4.jpg' },
  { id: 'NG', label: "NG", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201371848AM.jpg', group: 'RFD' },
  { id: 'CCC', label: "CCC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags413201494440PM.jpg', groups: ['White', 'RFD'] },
  { id: 'IRON', label: "IRON", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201310141AM.jpg', group: 'Oculus' },
  { id: 'NpO', label: "NpO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags530201495610AM.jpg', groups: ['Oculus', 'Dos Equis', 'Bluetooth'] },
  { id: 'TKTB', label: "TKTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/d/d7/Flag_of_TKTB.png' },
  { id: 'GLoF', label: "GLoF", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201361153PM.jpg', group: 'Oculus' },
  { id: 'GATO', label: "GATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013114249PM.jpg', groups: ['RFI', 'Butt'] },
  { id: 'Argent', label: "Argent", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags87202074410PM.jpg', group: 'RFI' },
  { id: 'TCTB', label: "TCTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/9/90/TCTB_Flag.png' },
  { id: 'MHA', label: "MHA", shape: 'image', image: 'https://www.cybernations.net/images/flags/Custom48.png', group: 'Dos Equis' },
  { id: 'OBR', label: "OBR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags162018123736PM.jpg' },
  { id: 'OG', label: "OG", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/62/Og.jpg' },
  { id: 'TLR', label: "TLR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags6242014114032AM.jpg' },
]

export const july22 = [
  { id: 'FTW', label: "FTW", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1302020121525PM.jpg', groups: ['White', 'RFD'] },
  { id: 'NATO', label: "NATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201385607PM.jpg', group: 'Bluetooth' },
  { id: 'DBDC', label: "DBDC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1219201463233PM.jpg' },
  { id: 'DS', label: "DS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/c/c9/Doom_Squad.png' },
  { id: 'CLAWS', label: "CLAWS", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201830850AM.jpg', font: { color: "pink", background: 'black' }, group: 'RFI' },
  { id: 'NPO', label: "NPO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013124311AM.jpg', group: 'Oculus' },
  { id: 'DT', label: "DT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201335929PM.jpg', group: 'Oculus' },
  { id: 'Legion', label: "Legion", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201392349PM.jpg', group: 'RFI' },
  { id: 'Sparta', label: "Sparta", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013125313AM.jpg' },
  { id: 'FAIL', label: "FAIL", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/6a/Failmark4.jpg' },
  { id: 'NG', label: "NG", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201371848AM.jpg', group: 'RFD' },
  { id: 'CCC', label: "CCC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags413201494440PM.jpg', groups: ['White', 'RFD'] },
  { id: 'IRON', label: "IRON", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201310141AM.jpg', group: 'Oculus' },
  { id: 'NpO', label: "NpO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags530201495610AM.jpg', groups: ['Oculus', 'Dos Equis', 'Bluetooth'] },
  { id: 'TKTB', label: "TKTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/d/d7/Flag_of_TKTB.png' },
  { id: 'GLoF', label: "GLoF", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201361153PM.jpg', group: 'Oculus' },
  { id: 'GATO', label: "GATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013114249PM.jpg', groups: ['RFI', 'Butt'] },
  { id: 'Argent', label: "Argent", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags87202074410PM.jpg', group: 'RFI' },
  { id: 'TCTB', label: "TCTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/9/90/TCTB_Flag.png' },
  { id: 'MHA', label: "MHA", shape: 'image', image: 'https://www.cybernations.net/images/flags/Custom48.png', group: 'Dos Equis' },
  { id: 'OBR', label: "OBR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags162018123736PM.jpg' },
  { id: 'OG', label: "OG", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/62/Og.jpg' },
  { id: 'TLR', label: "TLR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags6242014114032AM.jpg' },
  { id: 'Mongols', label: "Mongols", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/3/32/Flag_of_MONGOLS.jpg' },
  { id: 'VE', label: "VE", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags323201582938PM.jpg', group: 'Oculus'},
]

export const july24 = [
  { id: 'FTW', label: "FTW", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1302020121525PM.jpg', groups: ['White', 'RFD'] },
  { id: 'NATO', label: "NATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201385607PM.jpg', group: 'Bluetooth' },
  { id: 'DBDC', label: "DBDC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1219201463233PM.jpg' },
  { id: 'DS', label: "DS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/c/c9/Doom_Squad.png' },
  { id: 'CLAWS', label: "CLAWS", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201830850AM.jpg', font: { color: "pink", background: 'black' }, group: 'RFI' },
  { id: 'NPO', label: "NPO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013124311AM.jpg', group: 'Oculus' },
  { id: 'DT', label: "DT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201335929PM.jpg', group: 'Oculus' },
  { id: 'Legion', label: "Legion", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201392349PM.jpg', group: 'RFI' },
  { id: 'Sparta', label: "Sparta", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013125313AM.jpg' },
  { id: 'FAIL', label: "FAIL", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/6a/Failmark4.jpg' },
  { id: 'NG', label: "NG", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201371848AM.jpg', group: 'RFD' },
  { id: 'CCC', label: "CCC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags413201494440PM.jpg', groups: ['White', 'RFD'] },
  { id: 'IRON', label: "IRON", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201310141AM.jpg', group: 'Oculus' },
  { id: 'NpO', label: "NpO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags530201495610AM.jpg', groups: ['Oculus', 'Dos Equis', 'Bluetooth'] },
  { id: 'TKTB', label: "TKTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/d/d7/Flag_of_TKTB.png' },
  { id: 'GLoF', label: "GLoF", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201361153PM.jpg', group: 'Oculus' },
  { id: 'GATO', label: "GATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013114249PM.jpg', groups: ['RFI', 'Butt'] },
  { id: 'Argent', label: "Argent", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags87202074410PM.jpg', group: 'RFI' },
  { id: 'TCTB', label: "TCTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/9/90/TCTB_Flag.png' },
  { id: 'MHA', label: "MHA", shape: 'image', image: 'https://www.cybernations.net/images/flags/Custom48.png', group: 'Dos Equis' },
  { id: 'OBR', label: "OBR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags162018123736PM.jpg' },
  { id: 'OG', label: "OG", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/62/Og.jpg' },
  { id: 'TLR', label: "TLR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags6242014114032AM.jpg' },
  { id: 'Mongols', label: "Mongols", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/3/32/Flag_of_MONGOLS.jpg' },
  { id: 'VE', label: "VE", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags323201582938PM.jpg', group: 'Oculus'},
  { id: 'NoR', label: "NoR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags22201861445PM.jpg' },
  { id: 'Ouroboros', label: "Ouroboros", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/2/27/Flag_of_Ouroboros.png' },
]

export const july25 = [
  { id: 'FTW', label: "FTW", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1302020121525PM.jpg', groups: ['White', 'RFD'] },
  { id: 'NATO', label: "NATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201385607PM.jpg', group: 'Bluetooth' },
  { id: 'DBDC', label: "DBDC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1219201463233PM.jpg' },
  { id: 'DS', label: "DS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/c/c9/Doom_Squad.png' },
  { id: 'CLAWS', label: "CLAWS", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201830850AM.jpg', font: { color: "pink", background: 'black' }, group: 'RFI' },
  { id: 'NPO', label: "NPO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013124311AM.jpg', group: 'Oculus' },
  { id: 'DT', label: "DT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201335929PM.jpg', group: 'Oculus' },
  { id: 'Legion', label: "Legion", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201392349PM.jpg', group: 'RFI' },
  { id: 'Sparta', label: "Sparta", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013125313AM.jpg' },
  { id: 'FAIL', label: "FAIL", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/6a/Failmark4.jpg' },
  { id: 'NG', label: "NG", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201371848AM.jpg', group: 'RFD' },
  { id: 'CCC', label: "CCC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags413201494440PM.jpg', groups: ['White', 'RFD'] },
  { id: 'IRON', label: "IRON", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201310141AM.jpg', group: 'Oculus' },
  { id: 'NpO', label: "NpO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags530201495610AM.jpg', groups: ['Oculus', 'Dos Equis', 'Bluetooth'] },
  { id: 'TKTB', label: "TKTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/d/d7/Flag_of_TKTB.png' },
  { id: 'GLoF', label: "GLoF", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201361153PM.jpg', group: 'Oculus' },
  { id: 'GATO', label: "GATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013114249PM.jpg', groups: ['RFI', 'Butt'] },
  { id: 'Argent', label: "Argent", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags87202074410PM.jpg', group: 'RFI' },
  { id: 'TCTB', label: "TCTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/9/90/TCTB_Flag.png' },
  { id: 'MHA', label: "MHA", shape: 'image', image: 'https://www.cybernations.net/images/flags/Custom48.png', group: 'Dos Equis' },
  { id: 'OBR', label: "OBR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags162018123736PM.jpg' },
  { id: 'OG', label: "OG", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/62/Og.jpg' },
  { id: 'TLR', label: "TLR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags6242014114032AM.jpg' },
  { id: 'Mongols', label: "Mongols", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/3/32/Flag_of_MONGOLS.jpg' },
  { id: 'VE', label: "VE", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags323201582938PM.jpg', group: 'Oculus'},
  { id: 'NoR', label: "NoR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags22201861445PM.jpg' },
  { id: 'Ouroboros', label: "Ouroboros", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/2/27/Flag_of_Ouroboros.png' },
  { id: 'KoRT', label: "KoRT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201343715PM.jpg'},
]

export const july29 = [
  { id: 'FTW', label: "FTW", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1302020121525PM.jpg', groups: ['White', 'RFD'] },
  { id: 'NATO', label: "NATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201385607PM.jpg', group: 'Bluetooth' },
  { id: 'DBDC', label: "DBDC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1219201463233PM.jpg' },
  { id: 'DS', label: "DS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/c/c9/Doom_Squad.png' },
  { id: 'CLAWS', label: "CLAWS", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201830850AM.jpg', font: { color: "pink", background: 'black' }, group: 'RFI' },
  { id: 'NPO', label: "NPO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013124311AM.jpg', group: 'Oculus' },
  { id: 'DT', label: "DT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201335929PM.jpg', group: 'Oculus' },
  { id: 'Legion', label: "Legion", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201392349PM.jpg', group: 'RFI' },
  { id: 'Sparta', label: "Sparta", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013125313AM.jpg' },
  { id: 'FAIL', label: "FAIL", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/6a/Failmark4.jpg' },
  { id: 'NG', label: "NG", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201371848AM.jpg', group: 'RFD' },
  { id: 'CCC', label: "CCC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags413201494440PM.jpg', groups: ['White', 'RFD'] },
  { id: 'IRON', label: "IRON", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201310141AM.jpg', group: 'Oculus' },
  { id: 'NpO', label: "NpO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags530201495610AM.jpg', groups: ['Oculus', 'Dos Equis', 'Bluetooth'] },
  { id: 'TKTB', label: "TKTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/d/d7/Flag_of_TKTB.png' },
  { id: 'GLoF', label: "GLoF", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201361153PM.jpg', group: 'Oculus' },
  { id: 'GATO', label: "GATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013114249PM.jpg', groups: ['RFI', 'Butt'] },
  { id: 'Argent', label: "Argent", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags87202074410PM.jpg', group: 'RFI' },
  { id: 'TCTB', label: "TCTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/9/90/TCTB_Flag.png' },
  { id: 'MHA', label: "MHA", shape: 'image', image: 'https://www.cybernations.net/images/flags/Custom48.png', group: 'Dos Equis' },
  { id: 'OBR', label: "OBR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags162018123736PM.jpg' },
  { id: 'OG', label: "OG", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/62/Og.jpg' },
  { id: 'TLR', label: "TLR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags6242014114032AM.jpg' },
  { id: 'Mongols', label: "Mongols", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/3/32/Flag_of_MONGOLS.jpg' },
  { id: 'VE', label: "VE", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags323201582938PM.jpg', group: 'Oculus'},
  { id: 'NoR', label: "NoR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags22201861445PM.jpg' },
  { id: 'Ouroboros', label: "Ouroboros", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/2/27/Flag_of_Ouroboros.png' },
  { id: 'KoRT', label: "KoRT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201343715PM.jpg'},
  { id: 'PGS', label: "PGS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/8/81/Flag_of_PGS.jpg', groups: 'Butt' },
  { id: `"Kashmir"`, label: `"Kashmir"`, shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/a/ab/Official_Flag_of_Kashmir.gif' },
]

export const july30 = [
  { id: 'FTW', label: "FTW", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1302020121525PM.jpg', groups: ['White', 'RFD'] },
  { id: 'NATO', label: "NATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201385607PM.jpg', group: 'Bluetooth' },
  { id: 'DBDC', label: "DBDC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1219201463233PM.jpg' },
  { id: 'DS', label: "DS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/c/c9/Doom_Squad.png' },
  { id: 'CLAWS', label: "CLAWS", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201830850AM.jpg', font: { color: "pink", background: 'black' }, group: 'RFI' },
  { id: 'NPO', label: "NPO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013124311AM.jpg', group: 'Oculus' },
  { id: 'DT', label: "DT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201335929PM.jpg', group: 'Oculus' },
  { id: 'Legion', label: "Legion", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201392349PM.jpg', group: 'RFI' },
  { id: 'Sparta', label: "Sparta", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013125313AM.jpg' },
  { id: 'FAIL', label: "FAIL", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/6a/Failmark4.jpg' },
  { id: 'NG', label: "NG", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201371848AM.jpg', group: 'RFD' },
  { id: 'CCC', label: "CCC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags413201494440PM.jpg', groups: ['White', 'RFD'] },
  { id: 'IRON', label: "IRON", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201310141AM.jpg', group: 'Oculus' },
  { id: 'NpO', label: "NpO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags530201495610AM.jpg', groups: ['Oculus', 'Dos Equis', 'Bluetooth'] },
  { id: 'TKTB', label: "TKTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/d/d7/Flag_of_TKTB.png' },
  { id: 'GLoF', label: "GLoF", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201361153PM.jpg', group: 'Oculus' },
  { id: 'GATO', label: "GATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013114249PM.jpg', groups: ['RFI', 'Butt'] },
  { id: 'Argent', label: "Argent", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags87202074410PM.jpg', group: 'RFI' },
  { id: 'TCTB', label: "TCTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/9/90/TCTB_Flag.png' },
  { id: 'MHA', label: "MHA", shape: 'image', image: 'https://www.cybernations.net/images/flags/Custom48.png', group: 'Dos Equis' },
  { id: 'OBR', label: "OBR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags162018123736PM.jpg' },
  { id: 'OG', label: "OG", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/62/Og.jpg' },
  { id: 'TLR', label: "TLR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags6242014114032AM.jpg' },
  { id: 'Mongols', label: "Mongols", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/3/32/Flag_of_MONGOLS.jpg' },
  { id: 'VE', label: "VE", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags323201582938PM.jpg', group: 'Oculus'},
  { id: 'NoR', label: "NoR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags22201861445PM.jpg' },
  { id: 'Ouroboros', label: "Ouroboros", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/2/27/Flag_of_Ouroboros.png' },
  { id: 'KoRT', label: "KoRT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201343715PM.jpg'},
  { id: 'PGS', label: "PGS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/8/81/Flag_of_PGS.jpg', groups: 'Butt' },
  { id: `"Kashmir"`, label: `"Kashmir"`, shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/a/ab/Official_Flag_of_Kashmir.gif' },
  { id: 'WWF', label: "WWF", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags122201674402AM.jpg' },
]

export const july31 = [
  { id: 'FTW', label: "FTW", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1302020121525PM.jpg', groups: ['White', 'RFD'] },
  { id: 'NATO', label: "NATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201385607PM.jpg', group: 'Bluetooth' },
  { id: 'DBDC', label: "DBDC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1219201463233PM.jpg' },
  { id: 'DS', label: "DS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/c/c9/Doom_Squad.png' },
  { id: 'CLAWS', label: "CLAWS", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201830850AM.jpg', font: { color: "pink", background: 'black' }, group: 'RFI' },
  { id: 'NPO', label: "NPO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013124311AM.jpg', group: 'Oculus' },
  { id: 'DT', label: "DT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201335929PM.jpg', group: 'Oculus' },
  { id: 'Legion', label: "Legion", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201392349PM.jpg', group: 'RFI' },
  { id: 'Sparta', label: "Sparta", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013125313AM.jpg' },
  { id: 'FAIL', label: "FAIL", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/6a/Failmark4.jpg' },
  { id: 'NG', label: "NG", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201371848AM.jpg', group: 'RFD' },
  { id: 'CCC', label: "CCC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags413201494440PM.jpg', groups: ['White', 'RFD'] },
  { id: 'IRON', label: "IRON", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201310141AM.jpg', group: 'Oculus' },
  { id: 'NpO', label: "NpO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags530201495610AM.jpg', groups: ['Oculus', 'Dos Equis', 'Bluetooth'] },
  { id: 'TKTB', label: "TKTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/d/d7/Flag_of_TKTB.png' },
  { id: 'GLoF', label: "GLoF", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201361153PM.jpg', group: 'Oculus' },
  { id: 'GATO', label: "GATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013114249PM.jpg', groups: ['RFI', 'Butt'] },
  { id: 'Argent', label: "Argent", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags87202074410PM.jpg', group: 'RFI' },
  { id: 'TCTB', label: "TCTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/9/90/TCTB_Flag.png' },
  { id: 'MHA', label: "MHA", shape: 'image', image: 'https://www.cybernations.net/images/flags/Custom48.png', group: 'Dos Equis' },
  { id: 'OBR', label: "OBR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags162018123736PM.jpg' },
  { id: 'OG', label: "OG", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/62/Og.jpg' },
  { id: 'TLR', label: "TLR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags6242014114032AM.jpg' },
  { id: 'Mongols', label: "Mongols", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/3/32/Flag_of_MONGOLS.jpg' },
  { id: 'VE', label: "VE", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags323201582938PM.jpg', group: 'Oculus'},
  { id: 'NoR', label: "NoR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags22201861445PM.jpg' },
  { id: 'Ouroboros', label: "Ouroboros", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/2/27/Flag_of_Ouroboros.png' },
  { id: 'KoRT', label: "KoRT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201343715PM.jpg'},
  { id: 'PGS', label: "PGS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/8/81/Flag_of_PGS.jpg', groups: 'Butt' },
  { id: 'WWF', label: "WWF", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags122201674402AM.jpg' },
]

export const aug12 = [
  { id: 'FTW', label: "FTW", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1302020121525PM.jpg', groups: ['White', 'RFD'] },
  { id: 'NATO', label: "NATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201385607PM.jpg', group: 'Bluetooth' },
  { id: 'DBDC', label: "DBDC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1219201463233PM.jpg' },
  { id: 'DS', label: "DS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/c/c9/Doom_Squad.png' },
  { id: 'CLAWS', label: "CLAWS", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201830850AM.jpg', font: { color: "pink", background: 'black' }, group: 'RFI' },
  { id: 'NPO', label: "NPO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013124311AM.jpg', group: 'Oculus' },
  { id: 'DT', label: "DT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201335929PM.jpg', group: 'Oculus' },
  { id: 'Legion', label: "Legion", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201392349PM.jpg', group: 'RFI' },
  { id: 'Sparta', label: "Sparta", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013125313AM.jpg' },
  { id: 'FAIL', label: "FAIL", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/6a/Failmark4.jpg' },
  { id: 'NG', label: "NG", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201371848AM.jpg', group: 'RFD' },
  { id: 'CCC', label: "CCC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags413201494440PM.jpg', groups: ['White', 'RFD'] },
  { id: 'IRON', label: "IRON", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201310141AM.jpg', group: 'Oculus' },
  { id: 'NpO', label: "NpO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags530201495610AM.jpg', groups: ['Oculus', 'Dos Equis', 'Bluetooth'] },
  { id: 'TKTB', label: "TKTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/d/d7/Flag_of_TKTB.png' },
  { id: 'GLoF', label: "GLoF", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201361153PM.jpg', group: 'Oculus' },
  { id: 'GATO', label: "GATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013114249PM.jpg', groups: ['RFI', 'Butt'] },
  { id: 'Argent', label: "Argent", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags87202074410PM.jpg', group: 'RFI' },
  { id: 'TCTB', label: "TCTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/9/90/TCTB_Flag.png' },
  { id: 'MHA', label: "MHA", shape: 'image', image: 'https://www.cybernations.net/images/flags/Custom48.png', group: 'Dos Equis' },
  { id: 'OBR', label: "OBR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags162018123736PM.jpg' },
  { id: 'OG', label: "OG", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/62/Og.jpg' },
  { id: 'TLR', label: "TLR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags6242014114032AM.jpg' },
  { id: 'Mongols', label: "Mongols", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/3/32/Flag_of_MONGOLS.jpg' },
  { id: 'VE', label: "VE", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags323201582938PM.jpg', group: 'Oculus'},
  { id: 'NoR', label: "NoR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags22201861445PM.jpg' },
  { id: 'Ouroboros', label: "Ouroboros", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/2/27/Flag_of_Ouroboros.png' },
  { id: 'KoRT', label: "KoRT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201343715PM.jpg'},
  { id: 'PGS', label: "PGS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/8/81/Flag_of_PGS.jpg', groups: 'Butt' },
  { id: 'WWF', label: "WWF", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags122201674402AM.jpg' },
  { id: 'Fark', label: "Fark", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201344446PM.jpg', group: 'Dos Equis' },
  { id: 'FAN', label: "FAN", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201380131PM.jpg' },
]

export const aug14 = [
  { id: 'FTW', label: "FTW", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1302020121525PM.jpg', groups: ['White', 'RFD'] },
  { id: 'NATO', label: "NATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201385607PM.jpg', group: 'Bluetooth' },
  { id: 'DBDC', label: "DBDC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags1219201463233PM.jpg' },
  { id: 'DS', label: "DS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/c/c9/Doom_Squad.png' },
  { id: 'CLAWS', label: "CLAWS", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201830850AM.jpg', font: { color: "pink", background: 'black' }, group: 'RFI' },
  { id: 'NPO', label: "NPO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013124311AM.jpg', group: 'Oculus' },
  { id: 'DT', label: "DT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201335929PM.jpg', group: 'Oculus' },
  { id: 'Legion', label: "Legion", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201392349PM.jpg', group: 'RFI' },
  { id: 'Sparta', label: "Sparta", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013125313AM.jpg' },
  { id: 'FAIL', label: "FAIL", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/6a/Failmark4.jpg' },
  { id: 'NG', label: "NG", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201371848AM.jpg', group: 'RFD' },
  { id: 'CCC', label: "CCC", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags413201494440PM.jpg', groups: ['White', 'RFD'] },
  { id: 'IRON', label: "IRON", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags714201310141AM.jpg', group: 'Oculus' },
  { id: 'NpO', label: "NpO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags530201495610AM.jpg', groups: ['Oculus', 'Dos Equis', 'Bluetooth'] },
  { id: 'TKTB', label: "TKTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/d/d7/Flag_of_TKTB.png' },
  { id: 'GLoF', label: "GLoF", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201361153PM.jpg', group: 'Oculus' },
  { id: 'GATO', label: "GATO", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags7142013114249PM.jpg', groups: ['RFI', 'Butt'] },
  { id: 'Argent', label: "Argent", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags87202074410PM.jpg', group: 'RFI' },
  { id: 'TCTB', label: "TCTB", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/9/90/TCTB_Flag.png' },
  { id: 'MHA', label: "MHA", shape: 'image', image: 'https://www.cybernations.net/images/flags/Custom48.png', group: 'Dos Equis' },
  { id: 'OBR', label: "OBR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags162018123736PM.jpg' },
  { id: 'OG', label: "OG", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/6/62/Og.jpg' },
  { id: 'TLR', label: "TLR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags6242014114032AM.jpg' },
  { id: 'Mongols', label: "Mongols", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/3/32/Flag_of_MONGOLS.jpg' },
  { id: 'VE', label: "VE", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags323201582938PM.jpg', group: 'Oculus'},
  { id: 'NoR', label: "NoR", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags22201861445PM.jpg' },
  { id: 'Ouroboros', label: "Ouroboros", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/2/27/Flag_of_Ouroboros.png' },
  { id: 'KoRT', label: "KoRT", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags713201343715PM.jpg'},
  { id: 'PGS', label: "PGS", shape: 'image', image: 'https://static.wikia.nocookie.net/cybernations/images/8/81/Flag_of_PGS.jpg', groups: 'Butt' },
  { id: 'WWF', label: "WWF", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags122201674402AM.jpg' },
  { id: 'Fark', label: "Fark", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201344446PM.jpg', group: 'Dos Equis' },
  { id: 'FAN', label: "FAN", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags715201380131PM.jpg' },
  { id: 'RIA', label: "RIA", shape: 'image', image: 'https://www.cybernations.net/alliance_flags/active/alliance_flags42201472229PM.jpg', groups: ['Chestnut', 'Dos Equis'] },
]

export const aug16 = [
  ...aug14
]